import { useEffect, useState } from "react";
import { Table, Button } from "antd";
import ContentTitle from "../../../components/ui/ContentTitle";
// import Spinner from "../../../components/layout/spinner/Spinner";
import getInitialMarket from "../../../utils/getInitialMarket";
// import errorMessage from "../../trialMode/utils/errorMessage";
import { MarketType } from "../../../types/types";
import "./PushNotification.scss";
import MarketDropdown from "../../../components/ui/MarketDropdown";
import { PlusOutlined } from "@ant-design/icons";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { DataType } from "./types";
import { useGetAllNotificationsQuery } from "../../../graphql/operations/sales188/getAllNotification";
import { createColumns } from "./columns";
const SalesPushNotification = () => {
  const initialMarket = getInitialMarket();
  const [displayData, setDisplayData] = useState<DataType[] | any>([]);
  const [selectedMarket, setSelectedMarket] = useState<MarketType | any>(
    initialMarket
  );
  const { data, loading, error, refetch } = useGetAllNotificationsQuery();
  useEffect(() => {
    setDisplayData(data?.sales188Notification);
  }, [data]);
  useEffect(() => {
    const filtered = data?.sales188Notification.filter(
      (item) => item.market === selectedMarket
    );
    setDisplayData(filtered);
  }, [selectedMarket, data]);
  const currentUser = useReactiveVar(__currentUser__);
  useEffect(() => {
    if (currentUser?.afUserMarkets) {
      setSelectedMarket(currentUser?.afUserMarkets[0]?.market);
    }
  }, [currentUser]);
  return (
    <>
      <div id="sales188PushNotificationWrapper">
        <ContentTitle
          title="Push Notification"
          returnTitle="Notification detail"
        />
        <div className="wrapper">
          <div className="sales-notification-content-container">
            <div className="top-column">
              <div>
                {currentUser?.afUserMarkets &&
                currentUser?.afUserMarkets.length > 1 ? (
                  <MarketDropdown
                    selectedMarket={selectedMarket}
                    setSelectedMarket={setSelectedMarket}
                  />
                ) : (
                  <div />
                )}

                <Button
                  type="primary"
                  style={{ marginLeft: 8 }}
                  icon={<PlusOutlined />}
                >
                  Create New
                </Button>
              </div>
            </div>
            <div className="mt-4">
              <Table
                className="table"
                dataSource={displayData}
                columns={createColumns(setDisplayData, refetch)}
                scroll={{
                  x: true,
                  y: 500,
                }}
              ></Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesPushNotification;
