import { Button } from "antd";
import { DataType } from "./types";

interface Props {
  showDeleteButton: boolean;
  onView: () => void;
  refetch: any;
  record: DataType;
}

const TableActions = ({ showDeleteButton, onView, refetch, record }: Props) => {
  return (
    <div className="flex gap-4">
      {showDeleteButton ? (
        <Button type="link" className="w-9 p-0" onClick={onView}>
          Edit
        </Button>
      ) : (
        <Button type="link" className="w-9 p-0" onClick={onView}>
          View
        </Button>
      )}
      {showDeleteButton ? (
        <Button type="link" className="w-12 p-0" onClick={() => {}}>
          Delete
        </Button>
      ) : (
        <div />
      )}
    </div>
  );
};

export default TableActions;
