import { useEffect, useState } from "react";
import { Tabs, message } from "antd";
import type { TabsProps } from "antd";
import { Colors } from "../../constants/Colors";
import { data } from "../../data/data";
import ClubAppUserList from "./components/ClubAppUserList";
import { useGetAllUsersQuery } from "../../graphql/operations/get-all-users";
import Spinner from "../../components/layout/spinner/Spinner";
import MktUserList from "./components/MktUserList";
import SalesUserList from "./components/SalesUserList";
import TrialUserList from "./components/TrialUserList";
import { renderAntDMessageConfig } from "../../utils/renderAntDMessageConfig";
import { __isPermissionPage__ } from "../../graphql/policies";

const { services } = data;

const ProgramSetting = () => {
  const [clubAppUsers, setClubAppUsers] = useState<any>([]);
  const [mktUsers, setMktUsers] = useState<any>([]);
  const [trialUsers, setTrialUsers] = useState<any>([]);
  const [salesUsers, setSalesUsers] = useState<any>([]);
  const { data, loading, error } = useGetAllUsersQuery();
  __isPermissionPage__(true);

  const renderTabChildren = (index: number) => {
    switch (index) {
      case 0:
        return <ClubAppUserList users={clubAppUsers} />;
      case 5:
        return <MktUserList users={mktUsers} />;
      case 4:
        return <TrialUserList users={trialUsers} />;
      case 3:
        return <SalesUserList users={salesUsers} />;
      default:
        break;
    }
  };

  const items: TabsProps["items"] = services.map((item, i) => ({
    key: item.id.toString(),
    label: item.service,
    children: renderTabChildren(i),
    disabled: i !== 0 && i !== 4 && i !== 5 && i !== 3,
  }));

  useEffect(() => {
    if (data) {
      const usersOfClubApp =
        data.wf_adminFirst_afUser?.edges &&
        data.wf_adminFirst_afUser?.edges.map((user) => ({
          id: user.node.id,
          name: user.node.userName,
          market:
            user.node.afUserMarkets &&
            user.node.afUserMarkets.map((market) => market?.market),
          notification:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Notification"
            )
              ? true
              : false,
          faceCall:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Face Call"
            )
              ? true
              : false,
          announcementBar:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) =>
                program?.afProgram?.programName === "Announcement Bar"
            )
              ? true
              : false,
          serviceBanner:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Service Banner"
            )
              ? true
              : false,
          eventBanner:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Event Banner"
            )
              ? true
              : false,
          goGoLive:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Go, Go Live!"
            )
              ? true
              : false,
          importantNotice:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) =>
                program?.afProgram?.programName === "Important Notice"
            )
              ? true
              : false,
          waitingForReview:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) =>
                program?.afProgram?.programName === "Waiting for Review"
            )
              ? true
              : false,
          graduationReviewed:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) =>
                program?.afProgram?.programName === "Graduation Reviewed"
            )
              ? true
              : false,
          kidsInfo:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Kids Info"
            )
              ? true
              : false,
          releaseVersion:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Release Version"
            )
              ? true
              : false,
        }));
      const usersOfMkt =
        data.wf_adminFirst_afUser?.edges &&
        data.wf_adminFirst_afUser?.edges.map((user) => ({
          id: user.node.id,
          name: user.node.userName,
          market:
            user.node.afUserMarkets &&
            user.node.afUserMarkets.map((market) => market?.market),
          vendorReports:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Vendor Reports"
            )
              ? true
              : false,
          adExclusionList:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) =>
                program?.afProgram?.programName === "Ad Exclusion List"
            )
              ? true
              : false,
        }));
      const usersOfTrial =
        data.wf_adminFirst_afUser?.edges &&
        data.wf_adminFirst_afUser?.edges.map((user) => ({
          id: user.node.id,
          name: user.node.userName,
          market:
            user.node.afUserMarkets &&
            user.node.afUserMarkets.map((market) => market?.market),
          leadsDetailDownload:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) =>
                program?.afProgram?.programName === "Leads Detail Download"
            )
              ? true
              : false,
          campaignId:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Campaign ID"
            )
              ? true
              : false,
          localEvent:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Local Event"
            )
              ? true
              : false,
          caringCTA:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Caring CTA"
            )
              ? true
              : false,
          journeyCard:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Journey Card"
            )
              ? true
              : false,
          trialContent:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) => program?.afProgram?.programName === "Trial Content"
            )
              ? true
              : false,
          trialContentUpload:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) =>
                program?.afProgram?.programName === "Trial Content Upload"
            )
              ? true
              : false,
          pushNotification:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) =>
                program?.afProgram?.programName === "Push Notification"
            )
              ? true
              : false,
        }));
      const usersOfSales =
        data.wf_adminFirst_afUser?.edges &&
        data.wf_adminFirst_afUser?.edges.map((user) => ({
          id: user.node.id,
          name: user.node.userName,
          market:
            user.node.afUserMarkets &&
            user.node.afUserMarkets.map((market) => market?.market),
          releaseVersion:
            user.node.afUserPrograms &&
            user.node.afUserPrograms.find(
              (program) =>
                program?.afProgram?.programName === "Sales Release Version"
            )
              ? true
              : false,
        }));
      setClubAppUsers(usersOfClubApp);
      setMktUsers(usersOfMkt);
      setTrialUsers(usersOfTrial);
      setSalesUsers(usersOfSales);
    }
  }, [data]);

  if (error) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div
      className="permission-container"
      style={{ backgroundColor: Colors.lightWhite }}
    >
      <div
        className="permission-inner-container"
        style={{ backgroundColor: Colors.white }}
      >
        <div className="permission-header-container">
          <p>Program permission settings</p>
        </div>
        <div className="permission-content-container">
          <Tabs items={items} />
        </div>
      </div>
    </div>
  );
};

export default ProgramSetting;
