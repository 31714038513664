/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { message } from "antd";
import { ServiceTypes } from "../../../types/types";
import { __currentUser__ } from "../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";

interface ImportantNoticeProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const ImportantNotice = ({
  setSelectedService,
  setSelectSubItem,
}: ImportantNoticeProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });

  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("Face Call");
  }, []);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Important Notice"
    )
  ) {
    return <NoPermission />;
  }

  return <div>ImportantNotice</div>;
};

export default ImportantNotice;
