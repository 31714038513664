import * as Types from '../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllNotificationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllNotificationsQuery = { __typename?: 'Query', sales188Notification: Array<{ __typename?: 'Sales188Notification', notificationId: number, market?: string | null, sendingDepartment?: string | null, title?: string | null, templateMessage?: string | null, scheduleTime?: any | null, createdTime: any, creator?: string | null, removeNotificationId?: number | null, mutationOperation?: string | null, jobStatus?: string | null, error?: string | null, target?: string | null }> };


export const GetAllNotificationsDocument = gql`
    query getAllNotifications {
  sales188Notification {
    notificationId
    market
    sendingDepartment
    title
    templateMessage
    scheduleTime
    createdTime
    creator
    removeNotificationId
    mutationOperation
    jobStatus
    error
    target
  }
}
    `;

/**
 * __useGetAllNotificationsQuery__
 *
 * To run a query within a React component, call `useGetAllNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>(GetAllNotificationsDocument, options);
      }
export function useGetAllNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>(GetAllNotificationsDocument, options);
        }
export type GetAllNotificationsQueryHookResult = ReturnType<typeof useGetAllNotificationsQuery>;
export type GetAllNotificationsLazyQueryHookResult = ReturnType<typeof useGetAllNotificationsLazyQuery>;
export type GetAllNotificationsQueryResult = Apollo.QueryResult<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>;