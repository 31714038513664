import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  Radio,
  Select,
  Switch,
  Table,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import "./UserManagement.css";
import { Colors } from "../../constants/Colors";
import { NormalTagTypes } from "../../types/types";
import { data } from "../../data/data";
import RegionTag from "../../components/tags/RegionTag";
import NormalTag from "../../components/tags/NormalTag";
import DrawerFooter from "../../components/layout/drawer/DrawerFooter";
import InputCustom from "../../components/custom/InputCustom";
import { useGetAllUsersQuery } from "../../graphql/operations/get-all-users";
import Spinner from "../../components/layout/spinner/Spinner";
import { useCreateUserMutation } from "../../graphql/operations/create-user";
import { useUpdateUserMutation } from "../../graphql/operations/update-user";
import { renderDecodedId } from "../../utils/renderDecodedId";
import { useDeleteUserMutation } from "../../graphql/operations/delete-user";
import { renderAntDMessageConfig } from "../../utils/renderAntDMessageConfig";
import { __isPermissionPage__ } from "../../graphql/policies";

const { allMarkets, allServices } = data;

interface DataType {
  __typename?: "WF_AdminFirst_AfUser";
  id: string;
  userName?: string | null;
  userEmail?: string | null;
  isAdministrator?: boolean | null;
  avatarUrl?: string | null;
  afUserMarkets?: Array<{
    __typename?: "WF_AdminFirst_AfUserMarket";
    market?: string | null;
  } | null> | null;
  afUserServices?: Array<{
    __typename?: "WF_AdminFirst_AfUserService";
    afServiceId?: number | null;
  } | null> | null;
}

const renderServiceType = (serviceId: number) => {
  switch (serviceId) {
    case 2:
      return NormalTagTypes.CLUB_APP;
    case 3:
      return NormalTagTypes.CLUB_SERVICE;
    case 4:
      return NormalTagTypes.MKT;
    case 5:
      return NormalTagTypes.SALES_188;
    case 6:
      return NormalTagTypes.TECHPRESSO;
    case 7:
      return NormalTagTypes.CHECK_IN_APP;
    case 8:
      return NormalTagTypes.TRIAL_MODE;
    default:
      break;
  }
};

const UserManagement = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedUser, setSelectedUser] = useState<DataType | undefined>();
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [form] = Form.useForm();
  const { data, loading, error, refetch } = useGetAllUsersQuery({
    fetchPolicy: "no-cache",
  });
  const [createUser, { loading: createLoading }] = useCreateUserMutation();
  const [updateUser, { loading: updateLoading }] = useUpdateUserMutation();
  const [deleteUser, { loading: deleteLoading }] = useDeleteUserMutation();
  __isPermissionPage__(true);

  const handleSubmit = (values: any) => {
    const formData = {
      ...values,
      market: values.market.map((market: string) => ({ market })),
      serviceId: values.serviceId.map((service: string) => ({
        afServiceId: service,
      })),
    };
    if (selectedUser) {
      updateUser({
        variables: {
          ...formData,
          userId: parseInt(renderDecodedId(selectedUser.id)),
        },
      })
        .then(() => {
          refetch()
            .then(() => {
              form.resetFields();
              setShowDrawer(false);
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    } else {
      createUser({ variables: formData })
        .then(() => {
          refetch()
            .then(() => {
              form.resetFields();
              setShowDrawer(false);
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    }
  };

  const handleDelete = (id: string) => {
    setSelectedId(id);
    deleteUser({ variables: { userId: parseInt(renderDecodedId(id)) } })
      .then(() => refetch())
      .catch((e) => console.log(e));
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Market",
      dataIndex: "afUserMarkets",
      key: "afUserMarkets",
      render: (value: any) => (
        <div style={{ display: "flex" }}>
          {value.map((val: any) => (
            <div key={val.market} style={{ marginRight: 4 }}>
              <RegionTag region={val.market} />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Service",
      dataIndex: "afUserServices",
      key: "afUserServices",
      render: (value: any) => (
        <div style={{ display: "flex" }}>
          {value.map((val: any) => (
            <div key={val.afServiceId} style={{ marginRight: 4 }}>
              <NormalTag type={renderServiceType(val.afServiceId)} />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Administrator",
      dataIndex: "isAdministrator",
      key: "isAdministrator",
      render: (value: boolean) => <Switch checked={value} disabled />,
    },
    {
      title: "o365 account",
      dataIndex: "userEmail",
      key: "userEmail",
    },
    {
      title: "Actions",
      render: (_, record: DataType) => (
        <>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              setSelectedUser(record);
              setShowDrawer(true);
            }}
          >
            Edit
          </Button>
          <Button
            type="link"
            loading={deleteLoading && selectedId === record.id}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (showDrawer) {
      __isPermissionPage__(false);
    } else {
      __isPermissionPage__(true);
    }
  }, [showDrawer]);

  useEffect(() => {
    if (selectedUser) {
      form.setFieldsValue({
        email: selectedUser.userEmail,
        name: selectedUser.userName,
        market: selectedUser?.afUserMarkets?.map((item) => item?.market),
        isAdmin: selectedUser.isAdministrator,
        serviceId: selectedUser?.afUserServices?.map(
          (item) => item?.afServiceId
        ),
        avatarUrl: selectedUser.avatarUrl,
      });
    }
  }, [selectedUser, form]);

  if (error) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div
        className="permission-container"
        style={{ backgroundColor: Colors.lightWhite }}
      >
        <div
          className="permission-inner-container"
          style={{ backgroundColor: Colors.white }}
        >
          <div className="permission-header-container">
            <p>User management</p>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowDrawer(true)}
            >
              Create New
            </Button>
          </div>
          <div className="permission-content-container">
            <Table
              rowKey={(record: DataType) => record.id.toString()}
              columns={columns}
              dataSource={data?.wf_adminFirst_afUser?.edges?.map(
                (item) => item.node
              )}
              pagination={false}
              // Fixed table header and prevent the 'permission-content-container' from displaying a scroll bar
              scroll={{ x: true, y: window.innerHeight * 0.7 - 48 - 55 }}
            />
          </div>
        </div>
      </div>
      <Drawer
        title={selectedUser ? "Edit user" : "Create new user"}
        open={showDrawer}
        onClose={() => {
          setSelectedUser(undefined);
          form.resetFields();
          setShowDrawer(false);
        }}
        width={500}
      >
        <Form
          name="user-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          {selectedUser ? (
            <Form.Item label="o365 account" name="email">
              <p>{selectedUser?.userEmail}</p>
            </Form.Item>
          ) : (
            <Form.Item
              label="o365 account"
              name="email"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      data?.wf_adminFirst_afUser?.edges?.find(
                        ({ node: { userEmail } }) => userEmail === value
                      )
                    ) {
                      return Promise.reject(
                        new Error("The account was created before")
                      );
                    }
                    return Promise.resolve();
                  },
                },
                { required: true, message: "This field is required!" },
              ]}
            >
              <InputCustom />
            </Form.Item>
          )}
          <Form.Item
            label="User name"
            name="name"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <InputCustom />
          </Form.Item>
          <Form.Item
            label="Market(s)"
            name="market"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="- Select -"
              disabled={selectedUser ? true : false}
            >
              {allMarkets.map((market) => (
                <Select.Option key={market.id} value={market.market}>
                  {market.market}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Administrator"
            name="isAdmin"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Radio.Group
              options={[
                { label: "No", value: false },
                { label: "Yes", value: true },
              ]}
              optionType="button"
            />
          </Form.Item>
          <Form.Item
            label="Service(s)"
            name="serviceId"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="- Select -"
              disabled={selectedUser ? true : false}
            >
              {allServices.map((service) => (
                <Select.Option key={service.id} value={service.id}>
                  {service.service}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Avatar URL"
            name="avatarUrl"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <InputCustom />
          </Form.Item>
          <DrawerFooter
            handleCancel={() => {
              setSelectedUser(undefined);
              form.resetFields();
              setShowDrawer(false);
            }}
            isLoading={createLoading || updateLoading}
          />
        </Form>
      </Drawer>
    </>
  );
};

export default UserManagement;
